import React, { useState } from "react";
import BagIcon from "./BagIcon";

interface CounterButtonProps {
  onAddToCart: (quantity: number) => void;
}

const CounterButton: React.FC<CounterButtonProps> = ({ onAddToCart }) => {
  const [value, setValue] = useState<number>(1);

  const handleDecrement = () => setValue((prev) => Math.max(prev - 1, 1));
  const handleIncrement = () => setValue((prev) => prev + 1);

  return (
    <div className="h-10 w-32 flex gap-2.5">
      <div className="flex flex-row h-10 w-full rounded-lg bg-transparent mt-1">
        <button
          onClick={handleDecrement}
          className="bg-primaryMitosiz text-white hover:bg-purple-700 h-full w-20 rounded-l cursor-pointer"
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          type="number"
          id="custom-input-number"
          className="outline-none text-center w-full bg-primaryMitosiz font-semibold text-md focus:text-black cursor-default flex items-center text-white border-none focus:shadow-none focus:shadow-[none] focus:border-transparent"
          value={value}
          readOnly
        />
        <button
          onClick={handleIncrement}
          className="bg-primaryMitosiz text-white hover:bg-purple-700 h-full w-20 rounded-r cursor-pointer"
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
      <button
        onClick={() => onAddToCart(value)}
        className="mt-1 -mb-1 min-w-[40px] p-0 flex justify-center items-center bg-primaryMitosiz hover:bg-purple-700 text-white rounded-[50%] text-xs font-semibold transition-colors drop-shadow-[0_0_15px_rgba(0,0,0,0.25)]"
      >
        <BagIcon className="w-5 h-5 mb-0" />
      </button>
    </div>
  );
};

export default CounterButton;